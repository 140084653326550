<template>
  <view-item title="回访问卷">
    <template #operation>
      <en-button type="primary" :disabled="table.loading" @click="operation.add.click">新增回访问卷</en-button>
      <button-ajax type="primary" @click="operation.submit.click">保存</button-ajax>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-table :data="table.data" :loading="table.loading" :height="height">
            <en-table-column label="操作">
              <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitTagDto'] }">
                <button-delete :method="table.operation.delete.click" :params="row" :disabled="table.loading">删除</button-delete>
                <button-ajax  link @click="table.operation.edit.click(row)">编辑</button-ajax>
              </template>
            </en-table-column>
            <en-table-column label="问卷名称" prop="tag">
              <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitTagDto'] }">
                <span v-if="!row.active"> {{ row.tag }} </span>
                <en-input v-else v-model="row.tag" :disabled="table.loading"></en-input>
              </template>
            </en-table-column>
          </en-table>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            if (this.table.data.find((item) => !item.id)) return
            this.table.data.push({ tag: '', active: true })
          }
        },
        submit: {
          async click() {
            await this.table.submit()
            return this.table.get()
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/return/visit/tag',
            data: 'array',
            loading: true,
            convert(data: any) {
              data.forEach((item: any, index: Number) => {
                item.active = false
              })
            }
          },
          delete: {
            action: 'DELETE /enocloud/service/return/visit/tag/:tagId',
            loading: true
          },
          submit: {
            action: 'POST /enocloud/service/return/visit/tag',
            loading: true,
            params(params) {
              params.data = this.table.data
            }
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudServiceDefinitions['ServiceReturnVisitTagDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            edit: {
              click(row: EnocloudServiceDefinitions['ServiceReturnVisitTagDto']) {
                this.table.data.forEach((item) => {
                  item.active = false
                })
                row.active = true
              }
            }
          }
        }
      }
    }
  },

  async mounted() {
    await this.table.get()
  }
})
</script>
